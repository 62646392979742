.header
  position: fixed
  width: 100%
  top: 0
  left: 0
  background-color: transparent
  z-index: 12
  padding: 14px 0
  min-height: 35px
  color: $white
  @media (max-width: $width-tablet)
    min-height: 50px
    padding: 12px 0
  &.js-scroll
    background-color: $white
    color: $black
    border-bottom: 1px solid rgba(#000, 0.15)
  &.js-scroll
    svg
      fill: $black

  &__container
    display: flex
    align-items: center
    gap: 52px
    @media (max-width: $width-mobile)
      gap: 32px

  &__logo
    width: 50px
    min-width: 50px
    @media (max-width: $width-tablet)
      width: 40px
      min-width: 40px

  &__nav
    @media (max-width: $width-mobile)
      display: none

  &__nav-list
    display: flex
    align-items: center
    gap: 32px

  &__nav-item
    white-space: nowrap

  &__nav-link
    transition: $transition
    &:hover
      color: $accent

  &__contacts
    display: flex
    align-items: center
    white-space: nowrap
    transition: $transition
    &--email
      margin-left: auto
    &:hover
      text-decoration: underline
    svg
      display: none
    @media (max-width: $width-tablet)
      font-size: 14px
      svg
        display: block
        width: 24px
      span
        display: none