// Open Sans
@font-face
  font-family: 'OpenSans'
  font-weight: 600
  font-style: normal
  src: url("../fonts/OpenSans/OpenSans-SemiBold.woff2") format('woff2'), url("../fonts/OpenSans/OpenSans-SemiBold.woff") format('woff')

@font-face
  font-family: 'OpenSans'
  font-weight: 700
  font-style: normal
  src: url("../fonts/OpenSans/OpenSans-Bold.woff2") format('woff2'), url("../fonts/OpenSans/OpenSans-Bold.woff") format('woff')


// Average Sans
@font-face
  font-family: 'AverageSans'
  font-weight: 400
  font-style: normal
  src: url("../fonts/AverageSans/AverageSans-Regular.woff2") format('woff2'), url("../fonts/AverageSans/AverageSans-Regular.woff") format('woff')


// NunitoSans
@font-face
  font-family: 'NunitoSans'
  font-weight: 400
  font-style: normal
  src: url("../fonts/NunitoSans/NunitoSans-Regular.woff2") format('woff2'), url("../fonts/NunitoSans/NunitoSans-Regular.woff") format('woff')


@font-face
  font-family: 'NunitoSans'
  font-weight: 600
  font-style: normal
  src: url("../fonts/NunitoSans/NunitoSans-SemiBold.woff2") format('woff2'), url("../fonts/NunitoSans/NunitoSans-SemiBold.woff") format('woff')

@font-face
  font-family: 'NunitoSans'
  font-weight: 700
  font-style: normal
  src: url("../fonts/NunitoSans/NunitoSans-Bold.woff2") format('woff2'), url("../fonts/NunitoSans/NunitoSans-Bold.woff") format('woff')




html
  font-family: "NunitoSans", sans-serif
  font-weight: 400
  text-size-adjust: none
  -webkit-text-size-adjust: none
  line-height: 1.3
  font-size: 16px

h1, h2, h3, h4, h5, h6, p
  font-weight: 400
  & > strong
    font-weight: 400