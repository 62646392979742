$transition: all 0.24s ease-in-out

$white: #fff
$black: #030303

$accent: #F04747
$dark-red: #A93030
$additional-red: #9B2C2C
$pale-red: #F08383

$additional-white: #C5C5C5

$blue: #A2C1FD

$grey: #1A1A1A
$lightgrey: #9B9B9B

$width-tablet: 1100px
$width-mobile: 768px