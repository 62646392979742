.hero
  position: relative
  display: flex
  align-items: center
  min-height: 90vh
  background-color: #D9D9D9
  background-image: url("../img/cover.jpg")
  background-size: cover
  background-repeat: no-repeat
  background-position: center
  padding-top: 92px
  padding-bottom: 92px
  @media (max-width: $width-mobile)
    padding-top: 154px
    min-height: 100vh
    align-items: flex-start
  &::after
    content: ""
    position: absolute
    width: 100%
    height: 100%
    background-color: rgba($black, 0.5)
    top: 0
    left: 0

  &__container
    position: relative
    z-index: 1
    width: 100%
    display: flex
    flex-direction: column
    color: $white

  &__title
    @include fontSize(28, 64)
    margin-bottom: 16px
    font-weight: 700
  &__text
    margin-bottom: 64px
    @include fontSize(16, 20)
    max-width: 50%
    @media (max-width: $width-tablet)
      max-width: 80%
    @media (max-width: $width-mobile)
      max-width: initial
      margin-bottom: 200px

  &__button
    @media (max-width: $width-mobile)
      width: 100%