.footer
  font-size: 14px
  background-color: $black
  padding: 32px 0 12px
  color: $white
  @media (max-width: $width-mobile)
    padding-top: 24px

  &__container
    display: grid
    justify-content: space-between
    column-gap: 24px
    grid-template-areas: "logo contacts timetable map" "logo contacts author map" "copyright copyright copyright copyright"
    @media (max-width: $width-tablet)
      grid-template-areas: "logo contacts timetable" "logo contacts author" "map map map" "copyright copyright copyright"
      justify-content: initial
    @media (max-width: $width-mobile)
      grid-template-areas: "logo" "timetable" "contacts" "map" "author" "copyright"
      row-gap: 32px

  &__title
    font-size: 16px
    font-weight: 500
    margin-bottom: 6px
    @media (max-width: $width-mobile)
      margin-bottom: 0

  &__logo
    grid-area: logo
    display: block
    width: 50px
    @media (max-width: $width-mobile)
      width: 40px

  a
    transition: $transition
    &:hover
      color: $accent


  &-contacts
    display: flex
    flex-direction: column
    gap: 12px
    grid-area: contacts
    width: fit-content

    &__item
      display: flex
      align-items: center
      gap: 2px
    &__address
      max-width: 205px
      margin-top: auto
      @media (max-width: $width-mobile)
        margin-top: 12px
    &__item--mail svg
      @media (max-width: $width-mobile)
        width: 20px

  &__timetable
    display: flex
    flex-direction: column
    gap: 12px
    grid-area: timetable
    @media (max-width: $width-mobile)
      justify-content: flex-end


  &-author
    grid-area: author
    display: flex
    flex-direction: column
    justify-content: flex-end
    gap: 4px
    font-size: 12px
    color: #E5E5E5
    white-space: nowrap
    @media (max-width: $width-mobile)
      font-size: 14px
    &__item
      display: flex
      align-items: center
      gap: 4px
    a
      display: flex
      align-items: center
      gap: 2px
      &:hover
        color: $white

  &__map
    min-width: 300px
    width: 100%
    height: 180px
    grid-area: map
    overflow: hidden
    @media (max-width: $width-tablet) and (min-width: $width-mobile)
      margin-top: 32px

    #footer-map
      width: 100%
      height: 100%

  &__copyright
    margin-top: 32px
    grid-area: copyright
    font-size: 12px
    display: flex
    gap: 8px
    @media (max-width: $width-mobile)
      flex-direction: column
      gap: 8px
      margin-top: initial
  &__politics
    position: relative
    display: flex
    align-items: center
    gap: 8px
    &:hover
      text-decoration: underline
    &::before
      content: "|"
      display: block
      font-weight: 500
      @media (max-width: $width-mobile)
        display: none





