.container
  max-width: 1200px
  min-width: 300px
  padding: 0 120px
  margin: 0 auto
  box-sizing: content-box
  @media (max-width: $width-tablet)
    padding: 0 50px
  @media (max-width: $width-mobile)
    padding: 0 16px
  @media (max-width: 400px)
    padding: 0 8px