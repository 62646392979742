.portfolio

  &__list
    display: grid
    grid-template-columns: repeat(3, 1fr)
    gap: 32px
    overflow-x: auto
    &::-webkit-scrollbar
      width: 0
      height: 0
    @media (max-width: $width-tablet)
      gap: 16px
      margin-right: -50px
      padding-right: 50px
    @media (max-width: $width-mobile)
      gap: 12px
      margin-right: -16px
      padding-right: 16px

  &-item
    background-color: rgba(#000, 0.05)
    border-radius: 16px
    padding: 16px
    @media (max-width: $width-tablet)
      min-width: 312px
    @media (max-width: $width-mobile)
      padding: 14px
    @media (max-width: 400px)
      min-width: 280px

    &__video
      width: 100% !important
      height: auto !important
      object-fit: cover
      object-position: center
      border-radius: 14px
      aspect-ratio: 34 / 27
      padding-top: 0 !important
      overflow: hidden

    &__slider
      overflow: hidden
      aspect-ratio: 34 / 27
      border-radius: 14px
      height: auto !important
      img
        width: 100%
        height: 100%
        object-fit: cover

    &__title
      text-align: center
      font-weight: 600
      margin: 16px 0 8px
      @include fontSize(18, 20)

    &__work-list
      padding-left: 16px
      display: flex
      flex-direction: column
      gap: 4px
      li
        list-style: initial

  .portfolio-item--swiper
    position: relative

  .slider-nav__prev,
  .slider-nav__next
    position: absolute
    cursor: pointer
    z-index: 2
    top: 50%
    background-color: rgba(#fff, 0.8)
    width: 32px
    aspect-ratio: 1 / 1
    border-radius: 50%
    background-image: url("../img/icons/accord-arrow.svg")
    background-size: 14px 8px
    background-position: center
    background-repeat: no-repeat
    @media (max-width: $width-mobile)
      width: 24px
  .slider-nav__prev
    left: 12px
    transform: rotate(90deg) translateY(-50%)
  .slider-nav__next
    right: 12px
    transform: rotate(-90deg) translateY(-50%)

  .swiper-pagination
    display: flex
    align-items: center
    justify-content: center
    background-color: rgba(#fff, 0.8)
    border-radius: 16px
    padding: 4px
    font-size: 16px
    top: 12px
    right: 12px
    left: initial
    width: fit-content
    height: fit-content
    min-width: 50px
    @media (max-width: $width-mobile)
      font-size: 14px
      min-width: 46px
      height: 20px

