.estimate

  &__list
    display: flex
    flex-direction: column
    background-color: rgba(#000, 0.05)
    border-radius: 20px
    overflow: hidden

  &__item
    display: flex
    align-items: center
    justify-content: space-between
    gap: 64px
    padding: 20px 24px 8px
    @include fontSize(16, 20)
    font-weight: 600
    transition: $transition
    border-bottom: 1px solid rgba(#000, 0.15)
    &:hover
      background-color: #DADADA
      border-color: transparent
    &:last-child
      border-bottom: none
      padding: 20px 24px
      @media (max-width: $width-mobile)
        padding: 16px
    @media (max-width: $width-mobile)
      padding: 16px 16px 6px
      gap: 32px
      align-items: flex-end

  &__price
    white-space: nowrap