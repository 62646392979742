.feedback
  position: relative
  width: 100%
  padding: 0 16px
  @media (max-width: $width-mobile)
    padding: 0 8px

  &__container
    position: relative
    max-width: 400px
    padding: 32px
    color: $white
    border-radius: 24px
    background-color: $grey
    margin: 0 auto
    z-index: 1
    @media (max-width: $width-mobile)
      padding: 14px 14px 24px
      border-width: 4px

  &__title
    text-align: center

  &__form
    display: flex
    flex-direction: column
    align-items: center
    width: 100%
    gap: 20px
    @media (max-width: $width-mobile)
      gap: 16px
    label
      font-size: 18px
      width: 100%
      @media (min-width: $width-mobile)
        max-width: 320px
        font-size: 16px
    input
      margin-top: 8px
      width: 100%
      display: block
      padding: 8px
      min-height: 42px
      border: 1px solid $lightgrey
      font-size: 16px
      border-radius: 12px
      @media (max-width: $width-mobile)
        font-size: 14px
      &::placeholder
        font-variant-numeric: lining-nums

  button
    font-size: 20px
    padding: 8px
    min-height: 48px
    width: 100%
    font-weight: 500
    margin-top: 8px
    @media (max-width: $width-mobile)
      max-width: initial
      margin-top: 16px
      font-size: 18px

  .feedback-message__body-success,
  .feedback-message__body-error
    display: none
    text-align: center
    padding: 32px
    @media (max-width: $width-mobile)
      padding: 0 16px
    &.visible
      display: block

  &__approve
    opacity: 0.5
    font-size: 12px
    a
      text-decoration: underline